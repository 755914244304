<template>
  <v-container fluid>
    <!-- <div>
      {{ this.$route.query.id }}
    </div> -->
    <!-- <div>
      {{ 'duplicate: ' + this.duplicateChecker }}
    </div> -->
    <!-- <div
      v-if="this.duplicateChecker.length > 0"
      style="margin-left: 15px; margin-right: 15px; margin-bottom: 15px; background-color: #f5c4c4"
    >
      <div style="margin-left: 15px; margin-right: 15px">
        <b>Warnung:</b> Diese Überschreibung überschreibt bereits bestehende Überschreibung(en)
        bitte prüfen.
      </div>

      <div
        v-for="item in this.duplicateChecker"
        :key="item.id"
        style="margin-left: 15px; margin-right: 15px"
      >
        <span>
          {{ `Article: ${item}` }}
        </span>
        <v-checkbox
            v-model="ex4"
            label=item
            color="primary"
            value="primary"
            hide-details
          ></v-checkbox>
      </div>
      <v-btn tile class="ma-3" color="primary" @click="onShowClicked">
        <v-icon left> mdi-eye </v-icon>
        Show
      </v-btn>
    </div> -->
    <BaseTableWithPermissionCheck
      :items="items_filterd"
      :headers="headers"
      title="Überschreibung: NGW"
      componentName="InvoiceOverwriteNGW"
      :editMutation="'updateOverwriteNGW'"
      :deleteMutation="'updateOverwriteNGW'"
      @refresh="updateList"
      :loading="loading"
      isApprovedButton
      :userName="data.usr"
    />
  </v-container>
</template>

<script>
import { API } from 'aws-amplify';
import { listOverwriteNGWS } from '@/graphql/queries';
import BaseTableWithPermissionCheck from '@/components/BaseTableWithPermissionCheck.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'overwrite_NGW',
  components: {
    BaseTableWithPermissionCheck,
  },
  async created() {
    this.updateList();
    this.data.usr = this.kurzel;
    this.getCacheOverwriteNGWs();
    this.getCacheOverwriteNGWApprovalLut();
    this.getMarketingMailLUT();
  },
  watch: {
    '$route.query.id'() {
      if (this.$route.query.id) {
        this.items_filterd = this.items.filter((i) => i.timestamp === Number(this.$route.query.id));
        console.log(JSON.stringify(this.$route.query.id));
      } else {
        this.items_filterd = this.items;
      }
    },
  },
  methods: {
    updateList() {
      this.getList();
    },
    async getList() {
      this.loading = true;
      const response = await API.graphql({
        query: listOverwriteNGWS,
        variables: {
          limit: 1000
        }
      });
      this.items = response.data.listOverwriteNGWS.items.filter((i) => i.delete_marked === false);
      this.items.map((i) =>
        i.controller_confirmed
          ? (i.controller_confirmed_toshow = i.controller)
          : (i.controller_confirmed_toshow = '')
      );
      this.items.map((i) =>
        i.manager_confirmed
          ? (i.manager_confirmed_toshow = i.manager)
          : (i.manager_confirmed_toshow = '')
      );
      this.items.map((i) => {
        i.ngwIST = i.ngwValue.split(',')[5];
        i.ngwSOLL = i.newNGW.split(',')[5];
      });
      this.$store.commit('setOverWriteNGW_items', this.items);
      this.$store.commit('setOverWriteNGW_headers', this.headers);
      // console.log(this.items);
      
      if (this.$route.query.id) {
        this.items_filterd = this.items.filter((i) => i.timestamp === Number(this.$route.query.id));
        console.log(JSON.stringify(this.$route.query.id));
      } else {
        this.items_filterd = this.items;
      }
      this.loading = false;
    },
    getCacheOverwriteNGWs: function () {
      this.loading = true;
      API.get('datauiRestAPI', `/overwriteNGWCache?fachverlagArticleLut=${true}`, {})
        .then((result) => {
          let temp = JSON.parse(result);
          this.dropDownValues = temp;
          this.$store.commit('setOverWriteNGW_dropDownValues', this.dropDownValues);
        })
        .catch((err) => {
          console.log(err);
        });
      this.loading = false;
    },
    getCacheOverwriteNGWApprovalLut: function () {
      this.loading = true;
      API.get('datauiRestAPI', '/ngwApprovalLut', {})
        .then((result) => {
          result = JSON.parse(JSON.stringify(result));
          let uniqueSubpublisher = [...new Set(result.map((i) => i.subpublisher_displayname))];
          let list_m = {};
          uniqueSubpublisher.forEach((j) => {
            result.forEach((a) => {
              if (a.subpublisher_displayname === j) {
                let list = {
                  Controller: a.controller,
                  Manager: a.manager,
                };
                list_m[j] = list;
              }
            });
          });
          // console.log(list_m);
          this.approvalList = list_m;
          this.$store.commit('setOverWriteNGW_approvalList', this.approvalList);
        })
        .catch((err) => {
          console.log(err);
        });
      this.loading = false;
    },
    getMarketingMailLUT: function () {
      this.loading = true;
      API.get('datauiRestAPI', '/overwriteNGWControllerMailLut', {})
        .then((result) => {
          this.marketingMailLut = result;
          // this.marketingMailLut.forEach(i => console.log(i.subpublisher_displayname));
          this.$store.commit('setOverWriteNGW_controllerMailLut', this.marketingMailLut );
        })
        .catch((err) => {
          console.log(err);
        });
      this.loading = false;
    },
  },
  data() {
    return {
      headers: [
        // { text: 'Fachverlag', value: 'subpublisher_displayname' },
        { text: 'Artikel', value: 'article_displayname' },
        { text: 'Werbeweg ', value: 'marketing_type_cd', thStyle: { width: "3%" } },
        { text: 'Unter-werbeweg ', value: 'marketing_sub_type_cd', thStyle: { width: "3%" } },
        { text: 'Kondition', value: 'condition_group' },
        // { text: 'Betrachtungsmonat', value: 'anfo_month' },
        { text: 'Grenz-Typ Wandlung', value: 'ov_cp_minMax' },
        { text: 'Wandlung in Vert. bez.', value: 'cas_00_r03' },
        { text: 'Wandlung Überschreibung ', value: 'ov_cp_contractPayedRatioThreshold' },
        { text: 'Grenz-Typ Haltbarkeit', value: 'ov_m_minMax' },
        { text: 'Monate in Abo', value: 'cas_36_r03' },
        { text: 'Monate Überschreibung', value: 'ov_m_monthThreshold' },
        { text: 'NGW IST', value: 'ngwIST' },
        { text: 'NGW SOLL', value: 'ngwSOLL' },
        { text: 'Grund', value: 'reason' },
        { text: 'Gültig von', value: 'valid_from' },
        { text: 'Gültig bis', value: 'valid_until' },
        { text: 'Controller Approved', value: 'controller_confirmed_toshow' },
        { text: 'Manager Approved', value: 'manager_confirmed_toshow' },
        { text: 'Über-schreibung Aktiv', value: 'active' },
      ],
      items: [],
      items_filterd: [],
      loading: true,
      data: { usr: '' },
      dropDownValues: [],
      marketingMailLut: [],
      approvalList: [],
    };
  },
  computed: {
    ...mapGetters(['user', 'kurzel']),
  },
};
</script>
