<template>
  <v-container fluid>
    <!-- <div>
      {{ this.$route.query.id }}
    </div> -->
    <BaseTableWithPermissionCheck
      :items="items_filterd"
      :headers="headers"
      title="Überschreibung-Abrechnung: WKZ"
      componentName="InvoiceOverwriteWKZ"
      :editMutation="'updateOverwriteWKZ'"
      :deleteMutation="'updateOverwriteWKZ'"
      @refresh="updateList"
      :loading="loading"
      isApprovedButton
      :userName="data.usr"
    />
  </v-container>
</template>

<script>
import { API } from 'aws-amplify';
import { listOverwriteWKZS } from '@/graphql/queries';
import BaseTableWithPermissionCheck from '@/components/BaseTableWithPermissionCheck.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'overwrite_WKZ',
  components: {
    BaseTableWithPermissionCheck,
  },
  async created() {
    this.updateList();
    this.data.usr = this.kurzel;
    this.getCacheOverwriteWKZs();
    this.getCacheOverwriteArticleApprovalLut();
    this.getMarketingMailLUT();
  },
  watch: {
    '$route.query.id'() {
      if (this.$route.query.id) {
        this.items_filterd = this.items.filter((i) => i.timestamp === Number(this.$route.query.id));
        console.log(JSON.stringify(this.$route.query.id));
      } else {
        this.items_filterd = this.items;
      }
    },
  },
  methods: {
    updateList() {
      this.getList();
    },
    async getList() {
      this.loading = true;
      const response = await API.graphql({
        query: listOverwriteWKZS,
        variables: {
          limit: 1000
        }
      });
      this.items = response.data.listOverwriteWKZS.items.filter((i) => i.delete_marked === false);
      this.items.map(
        (i) =>
          (i.ngw_factor_overwritten_value = i.ngw_factor_overwritten_value
            ? parseFloat(i.ngw_factor_overwritten_value * 100).toFixed(2)
            : null)
      );
      this.items.map((i) =>
        i.controller_confirmed
          ? (i.controller_confirmed_toshow = i.controller)
          : (i.controller_confirmed_toshow = '')
      );
      this.items.map((i) =>
        i.manager_confirmed
          ? (i.manager_confirmed_toshow = i.manager)
          : (i.manager_confirmed_toshow = '')
      );
      // console.log(this.items[1].timestamp);
      if (this.$route.query.id) {
        this.items_filterd = this.items.filter((i) => i.timestamp === Number(this.$route.query.id));
        console.log(JSON.stringify(this.$route.query.id));
      } else {
        this.items_filterd = this.items;
      }
      this.loading = false;
    },
    getCacheOverwriteWKZs: function () {
      this.loading = true;
      API.get('datauiRestAPI', `/overWriteWKZCache?mandatFachverlagLut=${true}`, {})
        .then((result) => {
          let temp = JSON.parse(result);
          this.dropDownValues = temp;
          this.$store.commit('setOverWriteWKZ_dropDownValues', this.dropDownValues);
        })
        .catch((err) => {
          console.log(err);
        });
      this.loading = false;
    },
    getCacheOverwriteArticleApprovalLut: function() {
      this.loading = true;
      API.get('datauiRestAPI', '/approvalLut', {})
        .then((result) => {
          result = JSON.parse(JSON.stringify(result));
          let uniqueMandator = [...new Set(result.map((i) => i.mandator_cd))];
          let uniqueSubpublisher = [...new Set(result.map((i) => i.subpublisher_displayname))];
          let list_m = {};
          uniqueMandator.forEach((i) => {
            let list_s = {};
            uniqueSubpublisher.forEach((j) => {
              result.forEach((a) => {
                if (a.mandator_cd === i && a.subpublisher_displayname === j) {
                  let list = {
                    Controller: a.controller,
                    Manager: a.manager,
                  };
                  list_s[j] = list;
                }
              });
            });
            list_m[i] = list_s;
            // console.log(JSON.stringify(list_m[i]));
          });
          this.approvalList = list_m;
          this.$store.commit('setOverWriteArticle_approvalList', this.approvalList);
        })
        .catch((err) => {
          console.log(err);
        });
      this.loading = false;
    },
    getMarketingMailLUT: function () {
      this.loading = true;
      API.get('datauiRestAPI', '/overwriteWKZControllerMailLut', {})
        .then((result) => {
          // console.log([]);
          this.marketingMailLut = JSON.parse(JSON.stringify(result));
          // console.log(JSON.stringify(this.marketingMailLut));
          this.$store.commit('setOverWriteWKZ_controllerMailLut', this.marketingMailLut);
        })
        .catch((err) => {
          console.log(err);
        });
      this.loading = false;
    },
  },
  data() {
    return {
      headers: [
        { text: 'Mandant', value: 'mandator_cd' },
        { text: 'Fachverlag', value: 'subpublisher_displayname' },
        { text: 'WKZ ', value: 'marketing_cd' },
        { text: 'Ursprünglicher Abrechnungswert', value: 'ngw_cost_original', width: '5%' },
        {
          text: 'Prozentsatz vom NGW Überschrieben',
          value: 'ngw_factor_overwritten_value',
          width: '5%',
        },
        {
          text: 'Abrechnungs-Pauschale Überschrieben',
          value: 'ngw_cost_overwritten_value',
          width: '5%',
        },
        { text: 'Grund', value: 'reason' },
        { text: 'Gültig von', value: 'valid_from' },
        { text: 'Gültig bis', value: 'valid_until' },
        { text: 'Consultant Approved', value: 'controller_confirmed_toshow' },
        { text: 'Manager Approved', value: 'manager_confirmed_toshow' },
        { text: 'Überschreibung Aktiv', value: 'active' },
      ],
      items: [],
      items_filterd: [],
      loading: true,
      data: { usr: '' },
      dropDownValues: [],
      marketingMailLut: [],
      approvalList: [],
    };
  },
  computed: {
    ...mapGetters(['user', 'kurzel']),
  },
};
</script>
