<template>
  <v-container fluid>
    <!-- <div>
      {{ this.userName }}
    </div> -->
    <v-data-table
      dense
      v-model="selected"
      :headers="extendedHeader"
      :items="filteredItems"
      :item-class= "row_classes"  
      item-key="id"
      show-select
      class="elevation-1"
      single-select
      :search="search"
      hide-default-footer
      :sort-by="sortingKey"
      :items-per-page="-1"
      :loading="loading"
      loading-text="Wir suchen die Daten für dich raus."
      no-data-text="Es existiert noch kein Eintrag."
    >
      <template v-slot:item.price="{ item }">
        {{ (Math.round(item.price * 100) / 100).toFixed(2) }} €
      </template>
      <template v-slot:item.priceThreshold="{ item }">
        {{ (Math.round(item.priceThreshold * 100) / 100).toFixed(2) }} €
      </template>
      <template v-slot:item.monthThreshold="{ item }">
        {{ (Math.round(item.monthThreshold * 100) / 100).toFixed(0) }} Monate
      </template>
      <template v-slot:item.cpo="{ item }">
        {{ (Math.round(item.cpo * 100) / 100).toFixed(2) }} €
      </template>
      <template v-slot:item.totalCost="{ item }">
        {{ (Math.round(item.totalCost * 100) / 100).toFixed(2) }} €
      </template>
      <template v-slot:item.cost="{ item }">
        {{ (Math.round(item.cost * 100) / 100).toFixed(2) }} €
      </template>
      <template v-slot:item.validFrom="{ item }">
        {{ $moment(item.validFrom).format('YYYY-MM-DD') }}
      </template>
      <template v-slot:item.validTill="{ item }">
        {{ $moment(item.validTill).format('YYYY-MM-DD') }}
      </template>
      <template v-slot:item.fromDate="{ item }">
        {{ $moment(item.fromDate).format('YYYY-MM-DD') }}
      </template>
      <template v-slot:item.untilDate="{ item }">
        {{ item.untilDate ? $moment(item.untilDate).format('YYYY-MM-DD') : '-' }}
      </template>
      <template v-slot:item.marketingTypeCd="{ item }">
        {{ item.marketingTypeCd.code }} ({{ item.marketingTypeCd.description }})
      </template>
      <!-- <template v-slot:item.affiliateType="{ item }">
        {{ item.affiliateType.cd }} ({{ item.affiliateType.description }})
      </template>
      <template v-slot:item.agency="{ item }">
        {{ item.agency.cd }} ({{ item.agency.name }})
      </template>
      <template v-slot:item.network="{ item }">
        {{ item.network.cd }} ({{ item.network.name }})
      </template>
      <template v-slot:item.targetGroup="{ item }">
        {{ item.targetGroup.name }})
      </template>
      <template v-slot:item.subpublisher="{ item }">
        {{ item.subpublisher.cd }} ({{ item.subpublisher.name }})
      </template>
      <template v-slot:item.newsletter="{ item }">
        {{ item.newsletter.cd }} ({{ item.newsletter.name }})
      </template> -->
      <template v-slot:item.article="{ item }">
        {{ item.article && item.article.code }} - {{ item.article && item.article.title }} ({{
          item.article && item.article.number
        }})
      </template>
      <template v-slot:item.description="{ item }">
        {{ item.description === null ? 'keine Beschreibung' : item.description }}
      </template>
      <template v-slot:item.OneStepArticleMapping="{ item }">
        {{
          `${item.OneStepArticleMapping ? item.OneStepArticleMapping.mandatorCode : ''} -
            ${item.OneStepArticleMapping ? item.OneStepArticleMapping.articleCode : ''}`
        }}
      </template>
      <template v-slot:item.createdBy="{ item }">
        {{ `${$moment(item.createdAt).format('YYYY-MM-DD')} (${item.createdBy})` }}
      </template>
      <template v-slot:item.isEmf="{ item }">
        <v-simple-checkbox v-model="item.isEmf" disabled></v-simple-checkbox>
      </template>
      <template v-slot:item.isIndividualized="{ item }">
        <v-simple-checkbox v-model="item.isIndividualized" disabled></v-simple-checkbox>
      </template>
      <template v-slot:item.isApproved="{ item }">
        <v-simple-checkbox v-model="item.isApproved" disabled></v-simple-checkbox>
      </template>
      <template v-slot:item.isMandatory="{ item }">
        <v-simple-checkbox v-model="item.isMandatory" disabled></v-simple-checkbox>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title> {{ title }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Suche"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer />
          <span class="mx-2" :style="itemCout >= 500 ? 'color: red; font-weight: bold;' : ''">
            {{ `Anzahl: ${itemCout}` }}
          </span>
          <v-btn tile class="ma-3" color="success" @click="onNewClicked">
            <v-icon left> mdi-plus </v-icon>
            Neu
          </v-btn>
          <v-btn
            v-if="isApprovedButton"
            tile
            :disabled="approvalOK"
            class="ma-3"
            color="primary"
            @click="onApproveClicked"
          >
            <v-icon left> mdi-check-decagram </v-icon>
            Freigeben
          </v-btn>
          <v-btn
            tile
            v-if="isDuplicateButton"
            :disabled="!selected.length"
            class="ma-3"
            color="primary"
            @click="onDuplicateClicked"
          >
            <v-icon left> mdi-content-copy </v-icon>
            Duplizieren
          </v-btn>
          <v-btn
            tile
            :disabled="editPermission"
            class="ma-3"
            color="warning"
            @click="onEditClicked"
          >
            <v-icon left> mdi-pencil </v-icon>
            Editieren
          </v-btn>
          <v-btn tile :disabled="canDelete" class="ma-3" color="error" @click="onDeleteClick">
            <v-icon left> mdi-delete </v-icon>
            Löschen
          </v-btn>
          <!-- <div  v-if="title!=='Überschreibung-Abrechnung: NGW'" style="margin-bottom: 28px; margin-left: 20px"> -->
          <div style="margin-bottom: 28px; margin-left: 20px">
            <v-row>
              <v-btn
                tile
                class="ma-1 white--text"
                color="#279DD9"
                small
                style="width: 165px"
                :disabled="extendPermission"
                @click="onExtendClicked"
              >
                <v-icon left> mdi-google-circles-extended </v-icon>
                Verlängern
              </v-btn>
            </v-row>
            <v-row>
              <v-btn
                tile
                class="ma-1 white--text"
                color="#0E5082"
                small
                style="width: 165px"
                :disabled="extendPermission"
                @click="onNotExtendClick"
              >
                <v-icon left> mdi-store-remove </v-icon>
                Nicht Verlängern
              </v-btn>
            </v-row>
          </div>
        </v-toolbar>
      </template>
      <template v-slot:footer>
        <v-btn tile class="ma-4" color="error" v-if="nextToken" @click="loadMore">
          <v-icon left> mdi-view-grid-plus</v-icon>
          Mehr Daten Nachladen
        </v-btn>
      </template>
    </v-data-table>
    <DeleteModual @refresh="refresh" ref="deleteAnfoModual" />
    <ApproveModual @refresh="refresh" :approveTyp="getApprovalType" ref="approveAnfoModual" />
    <NotExtendModual @refresh="refresh" ref="notExtendModual" />
  </v-container>
</template>

<script>
import DeleteModual from '@/components/DeleteModualDeleteMarked.vue';
import ApproveModual from '@/components/ApproveModalOverwriteArticle.vue';
import NotExtendModual from '@/components/NotExtendModual.vue';

export default {
  name: 'BaseTable',
  props: {
    headers: {
      type: Array,
    },
    items: {
      type: Array,
    },
    title: {
      type: String,
    },
    componentName: {
      type: String,
      default: '',
    },
    nextToken: {
      type: String,
      default: null,
    },
    sortingKey: {
      type: String,
      default: 'cd',
    },
    itemCount: {
      type: Number,
      default: 0,
    },
    deleteMutation: {
      type: String,
    },
    editMutation: {
      type: String,
      default: 'nicht definiert',
    },
    loading: {
      type: Boolean,
    },
    isApprovedButton: {
      type: Boolean,
      default: false,
    },
    isDuplicateButton: {
      type: Boolean,
      default: false,
    },
    userName: {
      type: String,
    },
  },
  components: {
    DeleteModual,
    ApproveModual,
    NotExtendModual,
  },
  computed: {
    getUsrName() {
      return this.userName;
    },
    filteredItems() {
      return this.items;
    },
    itemCout() {
      return this.items.length;
    },
    extendedHeader() {
      return this.headers.concat([{ text: 'Erstellt', value: 'createdBy' }]);
    },
    createLink() {
      return `${this.componentName}Create`;
    },
    editLink() {
      return `${this.componentName}Update`;
    },
    duplicateLink() {
      return `${this.componentName}Duplicate`;
    },
    extendLink() {
      return `${this.componentName}Extend`;
    },
    getApprovalType() {
      if (this.selected[0]) {
        let temp = this.filteredItems.filter((i) => this.selected[0].id === i.id)[0];
        if (this.selected[0] && this.selected[0].manager === this.getUsrName) {
          return ['manager', temp.manager_confirmed];
        } else if (this.selected[0] && this.selected[0].controller === this.getUsrName) {
          return ['controller', temp.controller_confirmed];
        } else return [];
      } else {
        if (this.selected[0] && this.selected[0].manager === this.getUsrName) {
          return ['manager', this.selected[0].manager_confirmed];
        } else if (this.selected[0] && this.selected[0].controller === this.getUsrName) {
          return ['controller', this.selected[0].controller_confirmed];
        } else return [];
      }
      // if (this.selected[0] && this.selected[0].manager === this.getUsrName) {
      //     return ['manager', this.selected[0].manager_confirmed];
      //   } else if (this.selected[0] && this.selected[0].controller === this.getUsrName) {
      //     return ['controller', this.selected[0].controller_confirmed];
      //   } else return [];
    },
    approvalOK() {
      if (this.selected.length) {
        if (
          this.selected[0].manager === this.getUsrName ||
          this.selected[0].controller === this.getUsrName
        ) {
          return false;
        }
        return true;
      } else {
        return true;
      }
    },
    canDelete() {
      if (this.selected.length) {
        if (
          this.selected[0].controller === this.getUsrName ||
          this.selected[0].createdBy === this.getUsrName ||
          this.selected[0].manager === this.getUsrName
        ) {
          return false;
        }
        return true;
      } else {
        return true;
      }
    },
    editPermission() {
      console.log('DEBUG userName: '+this.getUsrName );
      if (this.selected.length && !this.selected[0].active) {
        console.log('DEBUG activ: '+this.selected[0].active + ', Manager: '+this.selected[0].manager+', Controller: '+this.selected[0].controller+(', Creator: '+this.selected[0].createdBy));
      }else if (this.selected.length && this.selected[0].active) {
        console.log('DEBUG activ: '+this.selected[0].active );
      }else{
        console.log('DEBUG: NOT SELECTED!');
      }
       
      if (this.selected.length && !this.selected[0].active) {
        if (
          this.selected[0].manager === this.getUsrName ||
          this.selected[0].controller === this.getUsrName ||
          this.selected[0].createdBy === this.getUsrName
        ) {
          console.log('DEBUG Edit Permission: '+this.getUsrName );
          return false;
        }
        return true;
      } else {
        return true;
      }
    },
    extendPermission() {
      if (this.selected.length) {
        if (
          this.selected[0].manager === this.getUsrName ||
          this.selected[0].controller === this.getUsrName ||
          this.selected[0].createdBy === this.getUsrName
        ) {
          return false;
        }
        return true;
      } else {
        return true;
      }
    },
  },
  methods: {
    onDuplicateClicked() {
      const item = this.selected[0];
      // console.log('DEBUG: ' + item);
      this.$router.push({
        name: this.duplicateLink,
        params: { id: item.id },
      });
    },
    loadMore() {
      this.$emit('loadMore');
    },
    onNewClicked() {
      this.$router.push({
        name: this.createLink,
      });
    },
    onExtendClicked() {
      const item = this.selected[0];
      this.$router.push({
        name: this.extendLink,
        params: { id: item.id },
      });
    },
    onNotExtendClick() {
      const items = this.selected[0];
      const id = this.selected[0].id;
      const _version = this.selected[0]._version;

      this.$refs.notExtendModual.show(items, id, _version, this.deleteMutation);
    },
    onApproveClicked() {
      const items = this.filteredItems.filter((i) => this.selected[0].id === i.id)[0];
      this.$refs.approveAnfoModual.show(items, this.editMutation);
    },
    onEditClicked() {
      const item = this.selected[0];
      this.$router.push({
        name: this.editLink,
        params: { id: item.id },
      });
    },
    onDeleteClick() {
      const items = this.selected[0];
      const id = this.selected[0].id;
      const _version = this.selected[0]._version;

      this.$refs.deleteAnfoModual.show(items, id, _version, this.deleteMutation);
    },
    refresh() {
      this.$emit('refresh');
    },
    row_classes(item){
      if (item.handled && item.handled=== 2 && item.expired) {
        return "grey"
      }
    },
  },
  data() {
    return {
      selected: [],
      search: '',
    };
  },
};
</script>

<style scoped>
tr:nth-child(even) {
  background-color: #f2f2f2;
}

</style>

<style>
.grey td{
  background-color: #ffffff;
  color: rgb(133, 133, 133);
}
</style>
