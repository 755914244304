<template>
  <v-container fluid>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="290">
        <v-card>
          <v-card-title class="headline">{{this.dialogTitle}}</v-card-title>

          <v-card-text>{{this.dialogText}}</v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn tile color="grey darken-1" text @click="cancel()"> Abbrechen </v-btn>

            <v-btn tile color="red darken-1" text :disabled="!approvable" @click="onApproveClicked()"> Freigeben </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar v-model="showError" color="error" y="top" :timeout="timeout">
      {{ 'Es ist ein  Fehler aufgetreten' }}
    </v-snackbar>
    <v-snackbar v-model="showSuccess" color="success" y="top" :timeout="timeout">
      {{ 'Der Tag wurde erfolgreich gelöscht' }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { API } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    dialog: false,
    showError: false,
    showSuccess: false,
    responseObj: null,
    timeout: 3000,
    id: null,
    _version: null,
    isApprovedControlling: false,
    isApprovedPublisher: false,
    items: {},
    approved: false,
  }),
  props: {
    approveTyp: Array,
  },
  computed: {
    ...mapGetters(['kurzel', 'user']),
    managerConfirmed(){
      if (this.approveTyp[0]==='manager') {
        return true
      }else
      return this.items.manager_confirmed
    },
    controllerConfirmed(){
      if (this.approveTyp[0]==='controller') {
        return true
      }else
      return this.items.controller_confirmed
    },
    checkActiv(){
      // console.log(this.items.manager_confirmed+' '+this.items.controller_confirmed);
      // console.log(this.managerConfirmed +' '+ this.controllerConfirmed);
      if (this.managerConfirmed && this.controllerConfirmed) {
        return true
      }else{
        return false
      }
    },
    approvable(){
       if (this.approveTyp[1]){
        return false
       }else return true 
    },
    dialogTitle(){
      if (this.approvable) {
        return 'Eintrag Freigeben'
      }else return 'Eintrag Freigegeben'
    },
    dialogText(){
      if (this.approvable) {
        return 'Willst du Wert wirklich freigeben?'
      }else return 'Eintrag ist schon freigegeben.'
    }
  },
  watch: {
    items(value) {
      this.isApprovedControlling = !!value.approvedControllingBy;
      this.isApprovedPublisher = !!value.approvedPublisherBy;
    },
  },
  methods: {
    onApproveClicked() {
      this.updateItemById(this.id, this._version, this.mutation, this.items).then(() => {
        this.dialog = false;
        this.$emit('refresh');
        // this.$router.go(this.$router.currentRoute)
      });
    },
    async updateItemById(id, _version, mutationName) {
      const inputObj = {
        id,
        _version,
        manager_confirmed: this.managerConfirmed,
        controller_confirmed: this.controllerConfirmed,
        active: this.checkActiv,
      };
      await API.graphql({ query: mutations[mutationName], variables: { input: inputObj } });
    },
    show(items, mutation) {
      this.dialog = true;
      this.id = items.id;
      this.items = items;
      this.mutation = mutation;
      this._version = items._version;
      this.selected = [];
    },
    getNewApprove(kurzel, old, trigger) {
      if (!trigger) {
        return null;
      }
      if (!!old === trigger) {
        return old;
      }
      return this.kurzel;
    },
    cancel() {
      this.dialog = false;
      this.$emit('dialogClose');
    },
  },
};
</script>
