<template>
  <BaseForm title="Artikel Überschreibung verlängern" @onConfirmClicked="onConfirmClicked">
    <form>
      <v-container fluid>
        <!-- <div>
          <form v-on:submit.prevent>
            <button @click="getMarketingMailLUT" class="button">{{JSON.stringify(this.cacheData.approvalList)}}</button>
          </form>
        </div> -->
        <v-row align="center" justify="center">
          <v-col cols="4">
            <v-select
              v-model="data.mandator_cd"
              :error-messages="mandatorError"
              required
              label="Mandant"
              item-value="code"
              item-text="name"
              :items="mandatorList"
              v-on:change="changeMandat"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="data.subpublisher_displayname"
              :error-messages="FachverlagError"
              required
              label="Fachverlag"
              item-value="code"
              item-text="name"
              :items="fachverlagList"
              v-on:change="changeFachverlag"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="data.article_code"
              :error-messages="articleError"
              required
              label="Artikel"
              item-value="id"
              item-text="code"
              :items="articleDDList"
              v-on:change="changeArtikel"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="data.article_displayname"
              :error-messages="artikelAnzeigError"
              required
              label="Artikel Anzeigename"
              item-value="id"
              item-text="code"
              :items="marketingTypList"
              v-on:change="changeMarketingTyp"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="data.marketing_type_cd"
              :error-messages="werbewegError"
              label="Werbeweg"
              :items="werbewegList"
              required
              item-value="code"
              item-text="name"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="ngwValue[0]" label="NGW" readonly suffix="€"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="ngwValue[1]"
              label="Prozentsatz vom NGW"
              readonly
              suffix="%"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="ngwValue[2]"
              label="Abrechnungspauschale"
              readonly
              suffix="€"
            ></v-text-field>
          </v-col>
          <v-container fluid>
            <br />
            <p>
              Art der Überschreibung: <b>{{ radios || 'null' }}</b>
            </p>
            <v-radio-group v-model="radios" mandatory>
              <v-row>
                <v-col cols="4">
                  <v-col cols="12">
                    <v-radio
                      label="Prozentsatz vom NGW"
                      value="Prozentsatz vom NGW"
                      v-on:change="changeToFactorOverwrite"
                    ></v-radio>
                    <v-text-field
                      v-model="data.ngw_factor_overwritten_value"
                      type="number"
                      :min="0"
                      :max="100"
                      required
                      :rules="factorInputRules"
                      :error-messages="abrechnungsFactorError"
                      label="Prozentsatz vom NGW"
                      :disabled="radios === 'Abrechnungspauschale'"
                      suffix="%"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-radio
                      label="Abrechnungspauschale"
                      value="Abrechnungspauschale"
                      v-on:change="changeToValueOverwrite"
                    ></v-radio>
                    <v-text-field
                      v-model="data.ngw_cost_overwritten_value"
                      type="number"
                      required
                      :error-messages="abrechnungsValueError"
                      label="Abrechnungspauschale"
                      :disabled="radios === 'Prozentsatz vom NGW'"
                      suffix="€"
                    ></v-text-field>
                  </v-col>
                </v-col>
                <v-col md="4" offset-md="4">
                  <v-text-field
                    v-model="newNGW"
                    :error-messages="newNGWError"
                    required
                    label="Abrechnungswert"
                    readonly
                    suffix="€"
                    background-color="#E8F5E9"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-container>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-text-field
              v-model="data.reason"
              :error-messages="reasonErrors"
              label="Grund (Freitext)"
              required
              @input="$v.data.reason.$touch()"
              @blur="$v.data.reason.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" class="ma-3">
          <v-col cols="6">
            <v-menu
              v-model="menuFrom"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="data.validFrom"
                  :error-messages="validFromError"
                  label="Gültig von"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="data.validFrom"
                :allowed-dates="validDate"
                @input="menuFrom = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="menuTill"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="data.validTill"
                  :error-messages="validTillError"
                  label="Gültig bis"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="data.validTill"
                :allowed-dates="validDate"
                @input="menuTill = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-text-field
              v-model="data.createdBy"
              :error-messages="createdByError"
              label="Editiert von"
              readonly
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" class="ma-3">
          <v-col cols="6">
            <v-select
              v-model="data.controller"
              :error-messages="controllerError"
              label="Consultant"
              :items="controllerEmailLut"
              required
              item-value="code"
              item-text="name"
              v-on:change="changeController"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="data.manager"
              :error-messages="managerError"
              label="Manager"
              :items="managerEmailLut"
              required
              item-value="code"
              item-text="name"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </form>
  </BaseForm>
</template>

<script>
import { API } from 'aws-amplify';
import { createOverwriteArticle, updateOverwriteArticle } from '@/graphql/mutations';
import { listOverwriteArticles, getOverwriteArticle } from '@/graphql/queries';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

// import approvalListFile from './approvalList.json';

import BaseForm from '@/components/baseCrud/BaseForm.vue';
// import BaseField from '@/components/baseCrud/BaseField.vue';

export default {
  name: 'app',
  mixins: [validationMixin],
  components: {
    BaseForm,
  },
  data() {
    return {
      radios: null,
      menuFrom: false,
      menuTill: false,
      item: {},
      data: {
        articleID: null,
        minMax: 'MIN',
        priceThreshold: null,
        reason: '',
        type: 'MONTH_PRICE',
        createdBy: '',
        validFrom: null,
        validTill: null,
        mandator_cd: null,
        subpublisher_displayname: null,
        article_code: null,
        article_displayname: null,
        marketing_type_cd: null,
        approvalList: null,
        ngw_factor_overwritten_value: null,
        ngw_cost_overwritten_value: null,
        manager: '',
        controller: '',
      },
      articleList: [],
      dropDownValues: null,
      mandatorList: null,
      marketingMailLut: null,
      factorInputRules: [
        // (v) => (this.radios === 'Prozentsatz vom NGW' && !!v) || 'Is required',
        (v) => (this.radios === 'Prozentsatz vom NGW' && v && v >= 0) || 'Factor should be above 0',
        (v) =>
          (this.radios === 'Prozentsatz vom NGW' && v && v <= 100) || 'Max should not be above 100',
      ],
    };
  },
  validations() {
    return {
      data: {
        mandator_cd: { required },
        subpublisher_displayname: { required },
        article_code: { required },
        article_displayname: { required },
        marketing_type_cd: { required },
        controller: { required },
        manager: { required },
        validFrom: { required },
        validTill: { required },
        minMax: { required },
        reason: { required },
        createdBy: { required },
        ngw_factor_overwritten_value: this.abrechnungsFactorRequired,
        ngw_cost_overwritten_value: this.abrechnungsValueRequired,
      },
      newNGW: { required },
    };
  },
  async created() {
    this.getArticleList();
    this.getCurrentItemById(this.currentId);
    this.dropDownValues = this.OverWriteArticle_dropDownValues;
    this.mandatorList = Object.keys(this.OverWriteArticle_dropDownValues).sort();
    this.marketingMailLut = this.OverWriteArticle_marketingMailLut;
    this.data.approvalList = this.OverWriteArticle_approvalList;
  },
  methods: {
    async getCurrentItemById(id) {
      // console.log('DEBUG: '+id);
      const response = await API.graphql({
        query: getOverwriteArticle,
        variables: { id },
      });
      // console.log('DEBUG: '+JSON.stringify(response.data.getOverwriteArticle));
      this.item = response.data.getOverwriteArticle;
      this.data.reason = this.item.reason;
      this.data.ngw_factor_overwritten_value = this.item.ngw_factor_overwritten_value
        ? parseFloat(this.item.ngw_factor_overwritten_value * 100).toFixed(2)
        : this.item.ngw_factor_overwritten_value;
      this.data.ngw_cost_overwritten_value = this.item.ngw_cost_overwritten_value;
      this.data.mandator_cd = this.item.mandator_cd;
      this.data.subpublisher_displayname = this.item.subpublisher_displayname;
      this.data.article_code = this.item.article_code;
      this.data.article_displayname = this.item.article_displayname;
      this.data.marketing_type_cd = this.item.marketing_type_cd;
      this.data.controller = this.item.controller;
      this.data.manager = this.item.manager;
      this.data.delete_marked = this.item.delete_marked;
      this.data.validFrom = new Date(
        new Date(new Date(this.item.valid_until)).getTime() + 1 * 24 * 60 * 60 * 1000
      )
        .toISOString()
        .split('T')[0];

      // console.log('DEBUG: TIME: ' + this.item.valid_until);
      this.data.validTill = new Date(
        new Date(new Date(this.item.valid_until)).getTime() + 30 * 24 * 60 * 60 * 1000
      )
        .toISOString()
        .split('T')[0];
      this.data.ngw_based_costs_percentage_overwritten =
        this.item.ngw_based_costs_percentage_overwritten;
      this.radios = this.item.ngw_based_costs_percentage_overwritten
        ? 'Prozentsatz vom NGW'
        : 'Abrechnungspauschale';
      this.manager_confirmed = this.item.manager_confirmed;
      this.controller_confirmed = this.item.controller_confirmed;
      this.data.createdBy = this.item.createdBy;
      this.data.usrmail = this.item.usrmail;
      this.timestamp = this.item.timestamp;
      // console.log('DEBUG1: '+JSON.stringify(response.data.getOverwriteArticle));
    },
    async createMethod() {
      this.timestamp = Math.floor(Date.now() / 1000);
      const input = {
        active: false,
        article_code: this.data.article_code,
        controller: this.data.controller,
        controller_confirmed: false,
        createdBy: this.data.createdBy,
        manager: this.data.manager,
        manager_confirmed: false,
        mandator_cd: this.data.mandator_cd,
        marketing_type_cd: this.data.marketing_type_cd,
        article_displayname: this.data.article_displayname,
        handled: 0,
        ngw: parseFloat(this.ngwValue),
        ngw_based_costs_percentage:
          this.radios === 'Prozentsatz vom NGW'
            ? parseFloat(this.ngwValue[1] / 100).toFixed(2)
            : null,
        ngw_based_costs_percentage_overwritten:
          this.radios === 'Prozentsatz vom NGW' ? true : false,
        ngw_cost: this.newNGW,
        ngw_cost_original: this.ngwValue[2],
        ngw_cost_overwritten_value: this.data.ngw_cost_overwritten_value
          ? parseFloat(this.data.ngw_cost_overwritten_value)
          : null,
        ngw_costs_overwritten: this.radios === 'Abrechnungspauschale' ? true : false,
        ngw_factor_overwritten_value: parseFloat(this.data.ngw_factor_overwritten_value)
          ? parseFloat(this.data.ngw_factor_overwritten_value / 100).toFixed(6)
          : null,
        reason: this.data.reason,
        usrmail: this.data.usrmail,
        subpublisher_displayname: this.data.subpublisher_displayname,
        timestamp: this.timestamp,
        valid_from: this.convertDate(this.data.validFrom),
        valid_until: this.convertDate(this.data.validTill),
        delete_marked: this.data.delete_marked,
      };
      // console.log('DEBUG: ' + JSON.stringify(input));
      //create new entry, or update the entry
      // console.log('DEBUG1: '+this.controller_confirmed);
      // console.log('DEBUG2: '+this.manager_confirmed);
      // console.log('DEBUG3: '+this.currentId);

      // if (this.controller_confirmed || this.manager_confirmed) {
      //   await API.graphql({
      //   query: createOverwriteArticle,
      //   variables: { input: input },
      // });
      // }else{
      //   input['id'] = this.currentId;
      //   await API.graphql({
      //   query: updateOverwriteArticle,
      //   variables: { input: input },
      // });

      // // console.log(JSON.stringify(input))
      // }
      // input['id'] = this.currentId;
      try {
        await API.graphql({
          query: createOverwriteArticle,
          variables: { input: input },
        });
      } catch (err) {
        console.log('cannot create extend: ', err);
      }
      try {
        await API.graphql({
          query: updateOverwriteArticle,
          variables: { input: { id: this.currentId, handled: 2 } },
        });
      } catch (err) {
        console.log('cannot update extend: ', err);
      }
      this.$router.push({ name: 'InvoiceOverwriteArticle' });
      this.$emit('refresh');
    },
    onConfirmClicked() {
      this.$v.$touch();
      if (this.$v.$anyError || this.validTillError.length || this.validFromError.length) {
        return;
      } else if (
        this.managerEmailLut.filter((e) => e.name === this.data.manager)[0] &&
        this.managerEmailLut.filter((e) => e.name === this.data.manager)[0].email &&
        this.controllerEmailLut.filter((e) => e.name === this.data.controller)[0] &&
        this.controllerEmailLut.filter((e) => e.name === this.data.controller)[0].email
      ) {
        this.createMethod();
        this.sendEmailApproval();
      } else {
        this.createMethod();
      }
    },
    sendEmailApproval: function () {
      const input = {
        body: {
          // edit: true,
          article_code: this.data.article_code,
          createdBy: this.data.createdBy,
          manager: this.data.manager,
          managerEmail: this.managerEmailLut.filter((e) => e.name === this.data.manager)[0].email,
          controller: this.data.controller,
          controllerEmail: this.controllerEmailLut.filter((e) => e.name === this.data.controller)[0]
            .email,
          mandator_cd: this.data.mandator_cd,
          subpublisher_displayname: this.data.subpublisher_displayname,
          werbeweg: this.data.marketing_type_cd,
          overwriteType: this.radios,
          overwriteValueOriginal:
            this.radios === 'Prozentsatz vom NGW'
              ? this.ngwValue[1].toString().replace('.', ',') + '%'
              : this.ngwValue[2].toString().replace('.', ','),
          overwriteValueNew:
            this.radios === 'Prozentsatz vom NGW'
              ? parseFloat(this.data.ngw_factor_overwritten_value).toString().replace('.', ',') +
                '%'
              : parseFloat(this.data.ngw_cost_overwritten_value).toString().replace('.', ','),
          marketingMailApprover: this.marketingMailApprover,
          approvalEmailSubject:
            'Überschreibung PX-Anfo-Abrechnungswert für ' +
            this.data.article_code +
            '/' +
            this.data.marketing_type_cd,
          approvalEmailContent: `Hallo,\n\nes liegt eine neue Überschreibung der Abrechnung für\n\nMandant: ${
            this.data.mandator_cd
          } \nFachverlag: ${this.data.subpublisher_displayname} \nArtikel: ${
            this.data.article_code
          }\nWerbeweg: ${this.data.marketing_type_cd}\n\nÜberschrieben wurde die ${
            this.radios
          } von ${
            this.radios === 'Prozentsatz vom NGW'
              ? this.ngwValue[1].toString().replace('.', ',') + '%'
              : this.ngwValue[2].toString().replace('.', ',')
          } auf ${
            this.radios === 'Prozentsatz vom NGW'
              ? parseFloat(this.data.ngw_factor_overwritten_value).toString().replace('.', ',') +
                '%'
              : parseFloat(this.data.ngw_cost_overwritten_value).toString().replace('.', ',')
          }.\nGrund: ${this.data.reason}\n\nBitte die Überschreibung prüfen und freigeben.\nLink: `,
          stillMailSubject:
            'INFO: Überschreibung PX-Anfo-Abrechnungswert für ' +
            this.data.article_code +
            '/' +
            this.data.marketing_type_cd,
          stillMailContent: `Hallo,\n\nes liegt eine neue Überschreibung der Abrechnung für\n\nMandant: ${
            this.data.mandator_cd
          } \nFachverlag: ${this.data.subpublisher_displayname}  \nArtikel: ${
            this.data.article_code
          }\nWerbeweg: ${this.data.marketing_type_cd}\n\nÜberschrieben wurde die ${
            this.radios
          } von ${
            this.radios === 'Prozentsatz vom NGW'
              ? this.ngwValue[1].toString().replace('.', ',') + '%'
              : this.ngwValue[2].toString().replace('.', ',')
          } auf ${
            this.radios === 'Prozentsatz vom NGW'
              ? parseFloat(this.data.ngw_factor_overwritten_value).toString().replace('.', ',') +
                '%'
              : parseFloat(this.data.ngw_cost_overwritten_value).toString().replace('.', ',')
          }.\nGrund: ${this.data.reason}\n\nLink: `,
          mailFoot: `invoice/overwriteArticle?id=${this.timestamp}\n\nViele Grüße,\n${this.data.createdBy}`,
        },
      };
      // console.log('managermail: ' + input.body.managerEmail);
      // console.log('controllermail: ' + input.body.controllerEmail);
      console.log('marketing still mail: ', this.marketingMailApprover);
      API.post('datauiRestAPI', '/sendApprovalEmail', input)
        .then((result) => {
          console.log('Email sent!' + JSON.stringify(result));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getArticleList() {
      this.loading = true;
      const response = await API.graphql({
        query: listOverwriteArticles,
        variables: {
          limit: 10000,
        },
      });
      this.articleList = response.data.listOverwriteArticles.items;
      this.loading = false;
    },
    convertDate(date) {
      return this.$moment(date, 'YYYY-MM-DD', 'de').format('YYYY-MM-DD');
    },
    onCancelClicked() {
      this.$router.go(-1);
    },
    articleDisplayText(item) {
      return `${item.mandatorCode} - ${item.articleCode}`;
    },
    changeMandat() {
      this.data.subpublisher_displayname = null;
      this.data.article_code = null;
      this.data.article_displayname = null;
      this.data.marketing_type_cd = null;
    },
    changeFachverlag() {
      this.data.article_code = null;
      this.data.article_displayname = null;
      this.data.marketing_type_cd = null;
    },
    changeArtikel() {
      this.data.article_displayname = null;
      this.data.marketing_type_cd = null;
    },
    changeMarketingTyp() {
      this.data.marketing_type_cd = null;
    },
    changeController() {
      this.data.manager = null;
    },
    changeToValueOverwrite() {
      this.data.ngw_factor_overwritten_value = null;
    },
    changeToFactorOverwrite() {
      this.data.ngw_cost_overwritten_value = null;
    },
    validDate(val) {
      let dateNow = new Date(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
      ).getTime();
      let checkerFrom = new Date(dateNow - 3 * 30 * 24 * 60 * 60 * 1000)
        .toISOString()
        .substr(0, 10);
      let checkerTill = new Date(dateNow + 6 * 30 * 24 * 60 * 60 * 1000)
        .toISOString()
        .substr(0, 10);
      return val >= checkerFrom && val <= checkerTill;
    },
  },
  computed: {
    fachverlagList() {
      if (!this.data.mandator_cd) {
        return [];
      } else return Object.keys(this.dropDownValues[this.data.mandator_cd]).sort();
    },
    articleDDList() {
      if (!this.data.mandator_cd || !this.data.subpublisher_displayname) {
        return [];
      } else
        return Object.keys(
          this.dropDownValues[this.data.mandator_cd][this.data.subpublisher_displayname]
        ).sort();
    },
    marketingTypList() {
      if (
        !this.data.mandator_cd ||
        !this.data.subpublisher_displayname ||
        !this.data.article_code
      ) {
        return [];
      } else
        return Object.keys(
          this.dropDownValues[this.data.mandator_cd][this.data.subpublisher_displayname][
            this.data.article_code
          ]
        ).sort();
    },
    werbewegList() {
      if (
        !this.data.mandator_cd ||
        !this.data.subpublisher_displayname ||
        !this.data.article_code ||
        !this.data.article_displayname
      ) {
        return [];
      } else
        return Object.keys(
          this.dropDownValues[this.data.mandator_cd][this.data.subpublisher_displayname][
            this.data.article_code
          ][this.data.article_displayname]
        ).sort();
    },
    ngwValue() {
      if (
        !this.data.mandator_cd ||
        !this.data.subpublisher_displayname ||
        !this.data.article_code ||
        !this.data.article_displayname ||
        !this.data.marketing_type_cd
      ) {
        return '';
      } else {
        let originalValue =
          this.dropDownValues[this.data.mandator_cd][this.data.subpublisher_displayname][
            this.data.article_code
          ][this.data.article_displayname][this.data.marketing_type_cd];
        let newValue = originalValue.map((val, i) => {
          if (i === 1) {
            // (i === 1 ? parseFloat(val) * 100 : val)
            return parseFloat(val) * 100;
          } else if (i === 2) {
            return parseFloat(val).toFixed(2);
          } else {
            return val;
          }
        });
        return newValue;
      }
    },
    marketingMailApprover() {
      if (!this.data.marketing_type_cd || !this.marketingMailLut) {
        return [];
      } else {
        let test = [];
        this.marketingMailLut.forEach((i) => {
          if (i.marketing_type_cd === this.data.marketing_type_cd) {
            test = i.marketing_manager;
          }
        });
        return test;
      }
    },
    newNGW() {
      if (this.ngwValue[0] && this.data.ngw_factor_overwritten_value) {
        return parseFloat(
          (this.ngwValue[0] * this.data.ngw_factor_overwritten_value) / 100
        ).toFixed(2);
      } else if (this.data.ngw_cost_overwritten_value) {
        return this.data.ngw_cost_overwritten_value;
      } else {
        return null;
      }
    },
    controllerEmailLut() {
      if (!this.data.mandator_cd || !this.data.subpublisher_displayname) {
        return [];
      } else {
        let controllerEmailLut = [];
        if (
          this.data.approvalList[this.data.mandator_cd] &&
          this.data.approvalList[this.data.mandator_cd][this.data.subpublisher_displayname]
        ) {
          this.data.approvalList[this.data.mandator_cd][this.data.subpublisher_displayname][
            'Controller'
          ].forEach((e) => {
            controllerEmailLut.push({ name: e.split('@')[0], email: e });
          });
        }
        return controllerEmailLut.sort();
      }
    },
    managerEmailLut() {
      if (!this.data.mandator_cd || !this.data.subpublisher_displayname) {
        return [];
      } else {
        let managerEmailLut = [];
        if (
          this.data.approvalList[this.data.mandator_cd] &&
          this.data.approvalList[this.data.mandator_cd][this.data.subpublisher_displayname]
        ) {
          this.data.approvalList[this.data.mandator_cd][this.data.subpublisher_displayname][
            'Manager'
          ].forEach((e) => {
            managerEmailLut.push({ name: e.split('@')[0], email: e });
          });
        }
        return managerEmailLut.sort();
      }
    },
    validFrom() {
      return this.item.valid[0];
    },
    validTill() {
      return this.item.valid[1];
    },
    mandatorError() {
      const errors = [];
      if (!this.$v.data.mandator_cd.$dirty) return errors;
      !this.$v.data.mandator_cd.required && errors.push('Is required.');
      // console.log('mandatorError'+errors);
      return errors;
    },
    FachverlagError() {
      const errors = [];
      if (!this.$v.data.subpublisher_displayname.$dirty) return errors;
      !this.$v.data.subpublisher_displayname.required && errors.push('Is required.');
      // console.log('FachverlagError'+errors);
      return errors;
    },
    articleError() {
      const errors = [];
      if (!this.$v.data.article_code.$dirty) return errors;
      !this.$v.data.article_code.required && errors.push('Is required.');
      // console.log('articleError'+errors);
      return errors;
    },
    artikelAnzeigError() {
      const errors = [];
      if (!this.$v.data.article_displayname.$dirty) return errors;
      !this.$v.data.article_displayname.required && errors.push('Is required.');
      // console.log('articleAnzeigError'+errors);
      return errors;
    },
    werbewegError() {
      const errors = [];
      if (!this.$v.data.article_displayname.$dirty) return errors;
      !this.$v.data.article_displayname.required && errors.push('Is required.');
      // console.log('werbwegError'+errors);
      return errors;
    },
    newNGWError() {
      const errors = [];
      if (!this.$v.newNGW.$dirty) return errors;
      !this.$v.newNGW.required && errors.push('overwrite factor or value is required.');
      // console.log('nweNGW Error'+errors);
      return errors;
    },
    controllerError() {
      const errors = [];
      if (!this.$v.data.controller.$dirty) return errors;
      !this.$v.data.controller.required && errors.push('Is required.');
      // console.log('controllerError'+errors);
      return errors;
    },
    managerError() {
      const errors = [];
      if (!this.$v.data.manager.$dirty) return errors;
      !this.$v.data.manager.required && errors.push('Is required.');
      // console.log('managerError'+errors);
      return errors;
    },
    reasonErrors() {
      const errors = [];
      if (!this.$v.data.reason.$dirty) return errors;
      !this.$v.data.reason.required && errors.push('Is required.');
      // console.log('reasonError'+errors);
      return errors;
    },
    validTillError() {
      const errors = [];
      if (!this.$v.data.validTill.$dirty) return errors;
      !this.$v.data.validTill.required && errors.push('Is required.');
      if (this.$v.data.validTill.$dirty && this.$v.data.validFrom.$dirty) {
        let till = new Date(this.data.validTill).getTime();
        let from = new Date(this.data.validFrom).getTime();
        let checker = till - from;

        if (checker < 0) {
          this.$v.data.validTill.required &&
            errors.push('Beginning date must be earlier than ending date');
          return errors;
        }
      }
      return errors;
    },
    validFromError() {
      const errors = [];
      if (!this.$v.data.validFrom.$dirty) return errors;
      !this.$v.data.validFrom.required && errors.push('Is required.');
      if (this.$v.data.validTill.$dirty && this.$v.data.validFrom.$dirty) {
        let till = new Date(this.data.validTill).getTime();
        let from = new Date(this.data.validFrom).getTime();
        let checker = till - from;

        if (checker < 0) {
          this.$v.data.validFrom.required &&
            errors.push('Beginning date must be earlier than ending date');
          return errors;
        }
      }
      return errors;
    },
    createdByError() {
      const errors = [];
      if (!this.$v.data.createdBy.$dirty) return errors;
      !this.$v.data.createdBy.required && errors.push('Is required.');
      // console.log('createdByError'+errors);
      return errors;
    },
    abrechnungsFactorRequired() {
      return this.radios === 'Prozentsatz vom NGW' ? { required } : {};
    },
    abrechnungsFactorError() {
      const errors = [];
      if (
        this.radios === 'Prozentsatz vom NGW' &&
        !this.$v.data.ngw_factor_overwritten_value.$dirty
      )
        return errors;
      this.radios === 'Prozentsatz vom NGW' &&
        !this.$v.data.ngw_factor_overwritten_value.required &&
        errors.push('Is required.');
      // console.log('managerError'+errors);
      return errors;
    },
    abrechnungsValueRequired() {
      return this.radios === 'Abrechnungspauschale' ? { required } : {};
    },
    abrechnungsValueError() {
      const errors = [];
      if (this.radios === 'Abrechnungspauschale' && !this.$v.data.ngw_cost_overwritten_value.$dirty)
        return errors;
      if (this.radios === 'Abrechnungspauschale') {
        !this.$v.data.ngw_cost_overwritten_value.required && errors.push('Is required.');
      }
      return errors;
    },

    ...mapGetters([
      'user',
      'kurzel',
      'OverWriteArticle_dropDownValues',
      'OverWriteArticle_approvalList',
      'OverWriteArticle_marketingMailLut',
    ]),
    currentId() {
      return this.$route.params.id;
    },
  },
};
</script>
