<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="350">
        <v-card>
          <v-card-title class="headline">Nicht verlängern</v-card-title>

          <v-card-text> Willst du den Eintrag wirklich nicht verlängern? </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn tile color="grey darken-1" text @click="cancel()"> Abbrechen </v-btn>

            <v-btn tile color="red darken-1" text @click="onDeleteClick()"> nicht verlängern </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar v-model="showError" color="error" y="top" :timeout="timeout">
      {{ 'Es ist ein  Fehler aufgetreten' }}
    </v-snackbar>
    <v-snackbar v-model="showSuccess" color="success" y="top" :timeout="timeout">
      {{ 'Der Tag wurde erfolgreich gelöscht' }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { API } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';

export default {
  data: () => ({
    dialog: false,
    showError: false,
    showSuccess: false,
    responseObj: null,
    timeout: 3000,
    id: null,
    _version: null,
    items: {},
  }),
  computed: {},
  methods: {
    onDeleteClick() {
      // console.log('ID: '+this.id);
      this.deleteItemById(this.id, this._version, this.mutation, this.items).then(() => {
        this.dialog = false;
        this.$emit('refresh');
        // this.$router.go(this.$router.currentRoute);
      });
    },
    async deleteItemById(id, _version, mutationName) {
      const inputObj = {
        id,
        _version,
        handled: 2,
        // delete_marked: true,
      };
      await API.graphql({ query: mutations[mutationName], variables: { input: inputObj } });
    },
    show(items, id, _version, mutation) {
      this.dialog = true;
      this.id = id;
      this.items = items;
      this.mutation = mutation;
      this._version = _version;
    },
    cancel() {
      this.dialog = false;
      this.$emit('dialogClose');
    },
  },
};
</script>
