<template>
  <BaseForm title="Neue WKZ Überschreibung" @onConfirmClicked="onConfirmClicked">
    <div style="margin: 20px 0px 20px 0px">
      <p class="text-xs-left red--text">
        <b
          >Sollte für einen Vertrag sowohl eine WKZ- als auch eine Artikelüberschreibung vorliegen,
          dann greift nur die WKZ-Überschreibung!</b
        >
      </p>
    </div>
    <form>
      <v-container fluid>
        <!-- <div>
          <form v-on:submit.prevent>
            <button @click="getMarketingMailLUT" class="button">{{ this.kurzel }}</button>
          </form>
        </div> -->
        <!-- <div>
          {{'Info: '+this.data.wkzList}}
        </div> -->
        <v-row align="center" justify="center">
          <v-col cols="4">
            <v-select
              v-model="data.mandator_cd"
              :error-messages="mandatorError"
              label="Mandant"
              :items="mandatorList"
              v-on:change="changeMandat"
              required
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="data.subpublisher_displayname"
              :error-messages="FachverlagError"
              label="Fachverlag"
              :items="fachverlagList"
              v-on:change="changeFachverlag"
              required
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              v-model="data.marketing_cd"
              :items="Object.keys(this.wkzList).sort()"
              :loading="this.loading"
              label="WKZ"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="ngwValue[0]" label="NGW" readonly suffix="€"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="ngwValue[1]"
              label="Prozentsatz vom NGW"
              readonly
              suffix="%"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="ngwValue[2]"
              label="Abrechnungspauschale"
              readonly
              suffix="€"
            ></v-text-field>
          </v-col>
          <v-container fluid>
            <br />
            <p>
              Art der Überschreibung: <b>{{ radios || 'null' }}</b>
            </p>
            <v-radio-group v-model="radios" mandatory>
              <v-row>
                <v-col cols="4">
                  <v-col cols="12">
                    <v-radio
                      label="Prozentsatz vom NGW"
                      value="Prozentsatz vom NGW"
                      v-on:change="changeToFactorOverwrite"
                    ></v-radio>
                    <v-text-field
                      v-model="data.ngw_factor_overwritten_value"
                      type="number"
                      :min="0"
                      :max="100"
                      required
                      :rules="factorInputRules"
                      :error-messages="abrechnungsFactorError"
                      label="Prozentsatz vom NGW"
                      :disabled="radios === 'Abrechnungspauschale'"
                      suffix="%"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-radio
                      label="Abrechnungspauschale"
                      value="Abrechnungspauschale"
                      v-on:change="changeToValueOverwrite"
                    ></v-radio>
                    <v-text-field
                      v-model="data.ngw_cost_overwritten_value"
                      type="number"
                      :error-messages="abrechnungsValueError"
                      required
                      label="Abrechnungspauschale"
                      :disabled="radios === 'Prozentsatz vom NGW'"
                      suffix="€"
                    ></v-text-field>
                  </v-col>
                </v-col>
                <v-col md="4" offset-md="4">
                  <v-text-field
                    v-model="newNGW"
                    :error-messages="newNGWError"
                    label="Abrechnungswert"
                    readonly
                    suffix="€"
                    background-color="#E8F5E9"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-container>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-text-field
              v-model="data.reason"
              :error-messages="reasonErrors"
              label="Grund (Freitext)"
              required
              @input="$v.data.reason.$touch()"
              @blur="$v.data.reason.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" class="ma-3">
          <v-col cols="6">
            <v-menu
              v-model="menuFrom"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="data.validFrom"
                  :error-messages="validFromError"
                  label="Gültig von"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="data.validFrom"
                :allowed-dates="validDate"
                @input="menuFrom = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="menuTill"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="data.validTill"
                  :error-messages="validTillError"
                  label="Gültig bis"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="data.validTill"
                :allowed-dates="validDate"
                @input="menuTill = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-text-field
              v-model="data.createdBy"
              :error-messages="createdByError"
              label="Erstellt von"
              readonly
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" class="ma-3">
          <v-col cols="6">
            <v-select
              v-model="data.controller"
              :error-messages="controllerError"
              label="Consultant"
              :items="controllerEmailLut"
              required
              item-value="code"
              item-text="name"
              v-on:change="changeController"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="data.manager"
              :error-messages="managerError"
              label="Manager"
              :items="managerEmailLut"
              required
              item-value="code"
              item-text="name"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </form>
  </BaseForm>
</template>

<script>
import { API } from 'aws-amplify';
import { createOverwriteWKZ } from '@/graphql/mutations';
import { listOverwriteWKZS } from '@/graphql/queries';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

import BaseForm from '@/components/baseCrud/BaseForm.vue';

export default {
  name: 'app',
  mixins: [validationMixin],
  components: {
    BaseForm,
  },
  data() {
    return {
      loading: false,
      radios: null,
      menuFrom: false,
      menuTill: false,
      data: {
        minMax: 'MIN',
        reason: '',
        type: 'MONTH_PRICE',
        createdBy: '',
        validFrom: null,
        validTill: null,
        mandator_cd: null,
        subpublisher_displayname: null,
        marketing_cd: null,
        // article_displayname: null,
        // marketing_type_cd: null,
        approvalList: null,
        ngw_factor_overwritten_value: null,
        ngw_cost_overwritten_value: null,
        manager: '',
        controller: '',
      },
      articleList: [],
      timestamp: 0,
      dropDownValues: null,
      mandatorList: null,
      marketingMailLut: null,
      factorInputRules: [
        // (v) => (this.radios === 'Prozentsatz vom NGW' &&!!v) || "Is required",
        (v) => (this.radios === 'Prozentsatz vom NGW' && v && v >= 0) || 'Factor should be above 0',
        (v) =>
          (this.radios === 'Prozentsatz vom NGW' && v && v <= 100) || 'Max should not be above 100',
      ],
      wkzList: [],
    };
  },
  validations() {
    return {
      data: {
        mandator_cd: { required },
        subpublisher_displayname: { required },
        marketing_cd: { required },
        // article_displayname: { required },
        // marketing_type_cd: { required },
        controller: { required },
        manager: { required },
        validFrom: { required },
        validTill: { required },
        minMax: { required },
        reason: { required },
        createdBy: { required },
        ngw_factor_overwritten_value: this.abrechnungsFactorRequired,
        ngw_cost_overwritten_value: this.abrechnungsValueRequired,
      },
      newNGW: { required },
    };
  },
  async created() {
    this.setCreatedBy();
    this.getArticleList();
    this.data.createdBy = this.kurzel;
    this.data.usrmail = this.user.email;
    this.dropDownValues = this.OverWriteWKZ_dropDownValues;
    this.mandatorList = Object.keys(this.OverWriteWKZ_dropDownValues).sort();
    this.marketingMailLut = this.OverWriteWKZ_controllerMailLut;
    this.data.approvalList = this.OverWriteArticle_approvalList;
  },
  watch: {
    'data.subpublisher_displayname'() {
      if (this.data.mandator_cd && this.data.subpublisher_displayname) {
        this.loading = true;
        this.wkzList = { 'loading...': {} };
        this.getCacheOverwriteWKZs(this.data.mandator_cd, this.data.subpublisher_displayname);
      }
    },
  },
  methods: {
    async createMethod() {
      this.timestamp = Math.floor(Date.now() / 1000); //Unix timestamp (in seconds)
      const input = {
        active: false,
        marketing_cd: this.data.marketing_cd,
        controller: this.data.controller,
        controller_confirmed: false,
        createdBy: this.data.createdBy,
        manager: this.data.manager,
        manager_confirmed: false,
        mandator_cd: this.data.mandator_cd,
        // marketing_type_cd: this.data.marketing_type_cd,
        // article_displayname: this.data.article_displayname,
        ngw: parseFloat(this.ngwValue),
        ngw_based_costs_percentage: parseFloat(this.ngwValue[1] / 100).toFixed(2),
        ngw_based_costs_percentage_overwritten:
          this.radios === 'Prozentsatz vom NGW' ? true : false,
        ngw_cost: this.newNGW,
        ngw_cost_original: this.ngwValue[2],
        ngw_cost_overwritten_value:
          this.radios === 'Abrechnungspauschale'
            ? parseFloat(this.data.ngw_cost_overwritten_value)
            : null,
        ngw_costs_overwritten: this.radios === 'Abrechnungspauschale' ? true : false,
        ngw_factor_overwritten_value:
          this.radios === 'Prozentsatz vom NGW'
            ? parseFloat(this.data.ngw_factor_overwritten_value / 100).toFixed(6)
            : null,
        reason: this.data.reason,
        usrmail: this.data.usrmail,
        subpublisher_displayname: this.data.subpublisher_displayname,
        timestamp: this.timestamp,
        valid_from: this.convertDate(this.data.validFrom),
        valid_until: this.convertDate(this.data.validTill),
        delete_marked: false,
        expired: false,
        handled: 0,
      };
      // console.log(JSON.stringify(input));
      await API.graphql({
        query: createOverwriteWKZ,
        variables: { input: input },
      });
      this.$router.push({ name: 'InvoiceOverwriteWKZList' });
      this.$emit('refresh');
    },
    async getCacheOverwriteWKZs(mandator_cd, subpublisher_displayname) {
      this.loading = true;
      let result = await API.get(
        'datauiRestAPI',
        `/overWriteWKZCache?mandator_cd=${mandator_cd}&subpublisher_displayname=${subpublisher_displayname}`,
        {}
      );
      this.wkzList = JSON.parse(result);
      // console.log('DEBUG1234: ' + this.wkzList);
      this.loading = false;
    },
    convertDate(date) {
      return this.$moment(date, 'YYYY-MM-DD', 'de').format('YYYY-MM-DD');
    },
    onConfirmClicked() {
      this.$v.$touch();
      if (this.$v.$anyError || this.validTillError.length || this.validFromError.length) {
        return;
      } else if (
        this.managerEmailLut.filter((e) => e.name === this.data.manager)[0] &&
        this.managerEmailLut.filter((e) => e.name === this.data.manager)[0].email &&
        this.controllerEmailLut.filter((e) => e.name === this.data.controller)[0] &&
        this.controllerEmailLut.filter((e) => e.name === this.data.controller)[0].email
      ) {
        this.createMethod().then(this.sendEmailApproval());
      } else {
        this.createMethod();
      }
    },
    async getArticleList() {
      this.loading = true;
      const response = await API.graphql({
        query: listOverwriteWKZS,
        variables: {
          limit: 100,
        },
      });
      this.articleList = response.data.listOverwriteWKZS.items;
      this.loading = false;
    },
    setCreatedBy() {
      if (this.user && this.user.attributes) {
        this.data.createdBy = this.user.attributes.email;
      }
    },
    onCancelClicked() {
      this.$router.go(-1);
    },
    articleDisplayText(item) {
      return `${item.mandatorCode} - ${item.articleCode}`;
    },
    onDateInputChanged(value) {
      const newDate = this.$moment(value, 'YYYY-MM-DD', 'de');
      if (newDate.isValid() || !this.field.mandatory) {
        this.onInputChanged(value);
      }
      this.$forceUpdate();
    },
    sendEmailApproval: function () {
      const input = {
        body: {
          marketing_cd: this.data.marketing_cd,
          createdBy: this.data.createdBy,
          manager: this.data.manager,
          managerEmail: this.managerEmailLut.filter((e) => e.name === this.data.manager)[0].email,
          controller: this.data.controller,
          controllerEmail: this.controllerEmailLut.filter((e) => e.name === this.data.controller)[0]
            .email,
          mandator_cd: this.data.mandator_cd,
          subpublisher_displayname: this.data.subpublisher_displayname,
          werbeweg: this.data.marketing_type_cd,
          overwriteType: this.radios,
          overwriteValueOriginal:
            this.radios === 'Prozentsatz vom NGW'
              ? this.ngwValue[1].toString().replace('.', ',') + '%'
              : this.ngwValue[2].toString().replace('.', ','),
          overwriteValueNew:
            this.radios === 'Prozentsatz vom NGW'
              ? parseFloat(this.data.ngw_factor_overwritten_value).toString().replace('.', ',') +
                '%'
              : parseFloat(this.data.ngw_cost_overwritten_value).toString().replace('.', ','),
          // marketingMailApprover: ['xueya.long@bertelsmann.de'],
          marketingMailApprover: this.marketingMailApprover,
          approvalEmailSubject:
            'Überschreibung PX-Anfo-Abrechnungswert für ' + this.data.marketing_cd,
          approvalEmailContent: `Hallo,\n\nes liegt eine neue Überschreibung der Abrechnung für\n\nMandant: ${
            this.data.mandator_cd
          } \nFachverlag: ${this.data.subpublisher_displayname} \nWKZ: ${
            this.data.marketing_cd
          } \n\nÜberschrieben wurde die ${this.radios} von ${
            this.radios === 'Prozentsatz vom NGW'
              ? this.ngwValue[1].toString().replace('.', ',') + '%'
              : this.ngwValue[2].toString().replace('.', ',')
          } auf ${
            this.radios === 'Prozentsatz vom NGW'
              ? parseFloat(this.data.ngw_factor_overwritten_value).toString().replace('.', ',') +
                '%'
              : parseFloat(this.data.ngw_cost_overwritten_value).toString().replace('.', ',')
          }.\nGrund: ${this.data.reason}\n\nBitte die Überschreibung prüfen und freigeben.\nLink: `,
          stillMailSubject:
            'INFO: Überschreibung PX-Anfo-Abrechnungswert für ' + this.data.marketing_cd,
          stillMailContent: `Hallo,\n\nes liegt eine neue Überschreibung der Abrechnung für\n\nMandant: ${
            this.data.mandator_cd
          } \nFachverlag: ${this.data.subpublisher_displayname} \nWKZ: ${
            this.data.marketing_cd
          } \n\nÜberschrieben wurde die ${this.radios} von ${
            this.radios === 'Prozentsatz vom NGW'
              ? this.ngwValue[1].toString().replace('.', ',') + '%'
              : this.ngwValue[2].toString().replace('.', ',')
          } auf ${
            this.radios === 'Prozentsatz vom NGW'
              ? parseFloat(this.data.ngw_factor_overwritten_value).toString().replace('.', ',') +
                '%'
              : parseFloat(this.data.ngw_cost_overwritten_value).toString().replace('.', ',')
          }.\nGrund: ${this.data.reason}\n\nLink: `,
          mailFoot: `invoice/overwriteWKZ/list?id=${this.timestamp}\n\nViele Grüße,\n${this.data.createdBy}`,
        },
      };
      // console.log('debug LUT: ' + this.marketingMailApprover);
      console.log('marketing still mail: ', this.marketingMailApprover);
      API.post('datauiRestAPI', '/sendApprovalEmail', input)
        .then((result) => {
          console.log('Email sent!' + JSON.stringify(result));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeMandat() {
      this.data.subpublisher_displayname = null;
      this.data.marketing_cd = null;
      this.wkzList = [];
    },
    changeFachverlag() {
      this.data.marketing_cd = null;
      this.wkzList = [];
    },
    changeController() {
      this.data.manager = null;
    },
    changeToValueOverwrite() {
      this.data.ngw_factor_overwritten_value = null;
    },
    changeToFactorOverwrite() {
      this.data.ngw_cost_overwritten_value = null;
    },
    validDate(val) {
      let dateNow = new Date(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
      ).getTime();
      let checkerFrom = new Date(dateNow - 3 * 30 * 24 * 60 * 60 * 1000)
        .toISOString()
        .substr(0, 10);
      let checkerTill = new Date(dateNow + 6 * 30 * 24 * 60 * 60 * 1000)
        .toISOString()
        .substr(0, 10);
      return val >= checkerFrom && val <= checkerTill;
    },
  },
  computed: {
    controllerEmailLut() {
      if (!this.data.mandator_cd || !this.data.subpublisher_displayname) {
        return [];
      } else {
        let controllerEmailLut = [];
        if (
          this.data.approvalList[this.data.mandator_cd] &&
          this.data.approvalList[this.data.mandator_cd][this.data.subpublisher_displayname]
        ) {
          this.data.approvalList[this.data.mandator_cd][this.data.subpublisher_displayname][
            'Controller'
          ].forEach((e) => {
            controllerEmailLut.push({ name: e.split('@')[0], email: e });
          });
        }
        return controllerEmailLut.sort();
      }
    },
    managerEmailLut() {
      if (!this.data.mandator_cd || !this.data.subpublisher_displayname) {
        return [];
      } else {
        let managerEmailLut = [];
        if (
          this.data.approvalList[this.data.mandator_cd] &&
          this.data.approvalList[this.data.mandator_cd][this.data.subpublisher_displayname]
        ) {
          this.data.approvalList[this.data.mandator_cd][this.data.subpublisher_displayname][
            'Manager'
          ].forEach((e) => {
            managerEmailLut.push({ name: e.split('@')[0], email: e });
          });
        }
        return managerEmailLut.sort();
      }
    },
    fachverlagList() {
      if (!this.data.mandator_cd) {
        return [];
        // } else return Object.keys(this.dropDownValues[this.data.mandator_cd]).sort();
      } else {
        let temp = [];
        temp = this.dropDownValues[this.data.mandator_cd];
        return temp.sort();
      }
    },
    articleDDList() {
      console.log('this.data.mandator_cd: ' + this.data.mandator_cd);
      console.log('this.data.subpublisher_displayname: ' + this.data.subpublisher_displayname);
      if (!this.data.mandator_cd || !this.data.subpublisher_displayname) {
        return [];
      } else {
        this.getCacheOverwriteWKZs(
          this.data.mandator_cd,
          this.data.subpublisher_displayname
        );
        // console.log('DEBUG: ' + Object.keys(List).sort());
        // return Object.keys(List).sort();
        return this.data.subpublisher_displayname;
        // return Object.keys(
        //   this.dropDownValues[this.data.mandator_cd][this.data.subpublisher_displayname]
        // ).sort();
      }
    },
    marketingMailApprover() {
      if (!this.data.subpublisher_displayname || !this.marketingMailLut) {
        return [];
      } else {
        let test = [];
        this.marketingMailLut.forEach((i) => {
          if (i.subpublisher_displayname === this.data.subpublisher_displayname) {
            test = i.controller;
          }
        });
        return test;
      }
    },
    ngwValue() {
      if (
        !this.data.mandator_cd ||
        !this.data.subpublisher_displayname ||
        !this.data.marketing_cd /*||*/
        // !this.data.article_displayname ||
        // !this.data.marketing_type_cd
      ) {
        return '';
      } else {
        let originalValue = this.wkzList[this.data.marketing_cd];
        let newValue = originalValue.map((val, i) => {
          if (i === 0) {
            return parseFloat(val).toFixed(4);
          }else if (i === 1) {
            // (i === 1 ? parseFloat(val) * 100 : val)
            return parseFloat(val) * 100;
          } else if (i === 2) {
            return parseFloat(val).toFixed(2);
          } else {
            return val;
          }
        });
        return newValue;
      }
    },
    newNGW() {
      if (this.ngwValue[0] && this.data.ngw_factor_overwritten_value) {
        return parseFloat(
          (this.ngwValue[0] * this.data.ngw_factor_overwritten_value) / 100
        ).toFixed(2);
      } else if (this.data.ngw_cost_overwritten_value) {
        return this.data.ngw_cost_overwritten_value;
      } else {
        return null;
      }
    },
    mandatorError() {
      const errors = [];
      if (!this.$v.data.mandator_cd.$dirty) return errors;
      !this.$v.data.mandator_cd.required && errors.push('Is required.');
      // console.log('mandatorError'+errors);
      return errors;
    },
    FachverlagError() {
      const errors = [];
      if (!this.$v.data.subpublisher_displayname.$dirty) return errors;
      !this.$v.data.subpublisher_displayname.required && errors.push('Is required.');
      // console.log('FachverlagError'+errors);
      return errors;
    },
    articleError() {
      const errors = [];
      if (!this.$v.data.marketing_cd.$dirty) return errors;
      !this.$v.data.marketing_cd.required && errors.push('Is required.');
      // console.log('articleError'+errors);
      return errors;
    },
    artikelAnzeigError() {
      const errors = [];
      if (!this.$v.data.article_displayname.$dirty) return errors;
      !this.$v.data.article_displayname.required && errors.push('Is required.');
      // console.log('articleAnzeigError'+errors);
      return errors;
    },
    werbewegError() {
      const errors = [];
      if (!this.$v.data.marketing_type_cd.$dirty) return errors;
      !this.$v.data.marketing_type_cd.required && errors.push('Is required.');
      // console.log('werbwegError'+errors);
      return errors;
    },
    newNGWError() {
      const errors = [];
      if (!this.$v.newNGW.$dirty) return errors;
      !this.$v.newNGW.required && errors.push('overwrite factor or value is required.');
      // console.log('nweNGW Error'+errors);
      return errors;
    },
    controllerError() {
      const errors = [];
      if (!this.$v.data.controller.$dirty) return errors;
      !this.$v.data.controller.required && errors.push('Is required.');
      // console.log('controllerError'+errors);
      return errors;
    },
    managerError() {
      const errors = [];
      if (!this.$v.data.manager.$dirty) return errors;
      !this.$v.data.manager.required && errors.push('Is required.');
      // console.log('managerError'+errors);
      return errors;
    },
    reasonErrors() {
      const errors = [];
      if (!this.$v.data.reason.$dirty) return errors;
      !this.$v.data.reason.required && errors.push('Is required.');
      // console.log('reasonError'+errors);
      return errors;
    },
    validTillError() {
      const errors = [];
      if (!this.$v.data.validTill.$dirty) return errors;
      !this.$v.data.validTill.required && errors.push('Is required.');
      if (this.$v.data.validTill.$dirty && this.$v.data.validFrom.$dirty) {
        let till = new Date(this.data.validTill).getTime();
        let from = new Date(this.data.validFrom).getTime();
        let checker = till - from;

        if (checker < 0) {
          this.$v.data.validTill.required &&
            errors.push('Beginning date must be earlier than ending date');
          return errors;
        }
      }
      return errors;
    },
    validFromError() {
      const errors = [];
      if (!this.$v.data.validFrom.$dirty) return errors;
      !this.$v.data.validFrom.required && errors.push('Is required.');
      if (this.$v.data.validTill.$dirty && this.$v.data.validFrom.$dirty) {
        let till = new Date(this.data.validTill).getTime();
        let from = new Date(this.data.validFrom).getTime();
        let checker = till - from;

        if (checker < 0) {
          this.$v.data.validFrom.required &&
            errors.push('Beginning date must be earlier than ending date');
          return errors;
        }
      }
      return errors;
    },
    createdByError() {
      const errors = [];
      if (!this.$v.data.createdBy.$dirty) return errors;
      !this.$v.data.createdBy.required && errors.push('Is required.');
      // console.log('createdByError'+errors);
      return errors;
    },
    abrechnungsFactorRequired() {
      return this.radios === 'Prozentsatz vom NGW' ? { required } : {};
    },
    abrechnungsFactorError() {
      const errors = [];
      if (
        this.radios === 'Prozentsatz vom NGW' &&
        !this.$v.data.ngw_factor_overwritten_value.$dirty
      )
        return errors;
      this.radios === 'Prozentsatz vom NGW' &&
        !this.$v.data.ngw_factor_overwritten_value.required &&
        errors.push('Is required.');
      // console.log('managerError'+errors);
      return errors;
    },
    abrechnungsValueRequired() {
      return this.radios === 'Abrechnungspauschale' ? { required } : {};
    },
    abrechnungsValueError() {
      const errors = [];
      if (this.radios === 'Abrechnungspauschale' && !this.$v.data.ngw_cost_overwritten_value.$dirty)
        return errors;
      if (this.radios === 'Abrechnungspauschale') {
        !this.$v.data.ngw_cost_overwritten_value.required && errors.push('Is required.');
      }
      return errors;
    },

    ...mapGetters([
      'user',
      'kurzel',
      'OverWriteWKZ_dropDownValues',
      'OverWriteArticle_approvalList',
      'OverWriteWKZ_controllerMailLut',
    ]),
  },
};
</script>
